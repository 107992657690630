/* eslint-disable */
import OktaVue, { navigationGuard } from '@okta/okta-vue'
import LoginCallback from '@/components/LoginCallback.vue'
import { OktaAuth } from '@okta/okta-auth-js'
import inject from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import HomeDefault from '@/components/HomeDefault.vue'
import HelpHome from '@/components/helpPages/HelpHome.vue'
import HelpGeneralMFa from '@/components/helpPages/HelpGeneralMfa.vue'
import HelpDcfGaaps from '@/components/helpPages/HelpDcfGaaps.vue'
import HelpAnrDec from '@/components/helpPages/HelpAnrDec.vue'
import HelpBenefits from '@/components/helpPages/HelpBenefits.vue'
import HelpAotS1111 from '@/components/helpPages/HelpAotS1111.vue'
import HelpTims from '@/components/helpPages/HelpTims.vue'
import HelpCdd from '@/components/helpPages/HelpCdd.vue'
import HelpDll from '@/components/helpPages/HelpDll.vue'
import HelpVdol from '@/components/helpPages/HelpVdol.vue'
import HelpDmvReg from '@/components/helpPages/HelpDmvReg.vue'
import TownClerks from '@/components/helpPages/TownClerks.vue'
import HelpVdhPassword from '@/components/helpPages/HelpVdhPassword.vue'
import Welcome from '@/components/Welcome.vue'
import WelcomeVdhPartner from '@/components/WelcomeVdhPartner.vue'
import Logout from '@/components/Logout.vue'


let envLinks = {
    env: process.env.VUE_APP_ENV,
    infoApp: process.env.VUE_APP_APP_DOMAIN,
    linkAdmin: process.env.VUE_APP_LINK_OKTA_ADMIN,
    linkUploader: process.env.VUE_APP_LINK_UPLOADER,
    linkOnlineApp: process.env.VUE_APP_LINK_ONLINE_APP,
    linkMyBenefits: process.env.VUE_APP_LINK_MYBENEFITS,
    bizPortal: process.env.VUE_APP_BIZ_PORTAL,
    cddFamily: process.env.VUE_APP_CDD_FAMILY,
    cddProgram: process.env.VUE_APP_CDD_PROGRAM,
    dcfGAAPS: process.env.VUE_APP_DCF_GAAPS,
    dllLicensing: process.env.VUE_APP_DLL_LICENSING,
    dllEducation: process.env.VUE_APP_DLL_EDUCATION,
    aoeLaces: process.env.VUE_APP_AOE_LACES,
    aotTrips: process.env.VUE_APP_AOT_TRIPS,
    aotUtilPermitting: process.env.VUE_APP_AOT_GEOWORKS,
    anrDecLoansGrants: process.env.VUE_APP_ANR_DEC_LOANS_GRANTS,
    aotTripsRollout: process.env.VUE_APP_AOT_TRIPS_ROLLOUT,
    laborClaimants: process.env.VUE_APP_LABORCLAIMS,
    laborUiFiling: process.env.VUE_APP_LABORUI,
    oktaDomain: process.env.VUE_APP_OKTA_DOMAIN,
    searchTerms: process.env.VUE_APP_SEARCH_TERMS.split(','),
    linkVdhTims: process.env.VUE_APP_VDH_TIMS,
    linkVdhSmiles: process.env.VUE_APP_VDH_SMILES
}

let router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'HomeDefault',
            component: HomeDefault,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-home',
            name: 'HelpHome',
            component: HelpHome,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-mfa',
            name: 'HelpGeneralMFa',
            component: HelpGeneralMFa,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-tims',
            name: 'HelpTims',
            component: HelpTims,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-benefits',
            name: 'HelpBenefits',
            component: HelpBenefits,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-cdd',
            name: 'HelpCdd',
            component: HelpCdd,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-cdd-providers',
            name: 'HelpCddProvider',
            component: HelpCdd,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-dcf-gaaps',
            name: 'HelpDcfGaaps',
            component: HelpDcfGaaps,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-dll',
            name: 'HelpDll',
            component: HelpDll,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-vdol',
            name: 'HelpVdol',
            component: HelpVdol,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-dmv',
            name: 'HelpDmvReg',
            component: HelpDmvReg,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-aot-s1111',
            name: 'HelpAotS1111',
            component: HelpAotS1111,
            props: {
                envLinks: envLinks,
            },
        },        {
            path: '/help-anr-dec',
            name: 'HelpAnrDec',
            component: HelpAnrDec,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/townclerks',
            name: 'TownClerks',
            component: TownClerks,
            props: {
                envLinks: envLinks,
            },
        },
        {
            path: '/help-vdh-password',
            name: 'HelpVdhPassword',
            component: HelpVdhPassword,
        },
        {
            path: '/callback',
            component: LoginCallback,
        },
        {
            path: '/welcome',
            name: 'Welcome',
            component: Welcome,
            props: {
                envLinks: envLinks,
            },
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/welcome-vdh-partner',
            name: 'WelcomeVdhPartner',
            component: WelcomeVdhPartner,
            props: {
                envLinks: envLinks,
            },
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            props: {
                envLinks: envLinks,
            },
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'Default',
            component: HomeDefault,
            props: {
                envLinks: envLinks,
            },
        },
    ],
})

router.beforeEach(navigationGuard)
export default router
