<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Welcome to the The Right-of-Way Section at the Agency of Transportation!</h1>
                    <div class="sub-headings">
                        <p><b>How can we help you?</b></p>
                        <div class="image-row">
                            <S1111Logo class="help-icon" />
                        </div>
                        <p class="heading-instruction">Please check the common problems and solutions for access to the ROW permitting system before contacting customer support.</p>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <h2 class="faq-header">How to Register for Aot Applications</h2>
                <p class="faq-text">A myVermont account will allow you to access ROW registration functions on <a class="email-link" :href="appLink">this page</a>. If you do not have a myVermont account, just go to that link, and click the sign-up button.</p>
                <HelpGeneral :envLinks="envLinks"></HelpGeneral>
                <div class="noteAlt">
                    <i><font-awesome-icon icon="info-circle" /></i>
                    <div class="noteText">
                        <p>If you do not find an answer here, please submit an S.1111 Helpdesk Request.</p>
                        <p>To submit a request, please <a href="https://forms.office.com/g/A3aRDBp0TU">find the form at this link</a>. Please be complete as possible in responding the form including your contact information and availability. A representative should typically be able to respond within 48 hours or less to most requests.</p>
                        <p><u>
                            Please do not include any personal or identifying information (like Date of Birth or SSN) in your email. If necessary, you will be contacted by a representative to assist you via phone.</u>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelpGeneral from './HelpGeneral'
import S1111Logo from '../../assets/aotRowIcon.vue'

export default {
    name: 'help-benefits',
    props: ['envLinks'],
    components: { HelpGeneral, S1111Logo },
    data() {
        return {
            appLink: this.envLinks.aotUtilPermitting
        }
    }
}
</script>

<style scoped>
@import './help.css';
</style>
