<template>
    <div>
        <h2 class="faq-header">Multifactor Authentication (MFA)</h2>
        <p class="faq-text">When you use your myVermont account to access {{appsRequiring}}, multifactor authentication is
            required. This means that in addition to your password, you will choose at least one of several
            other security options to access the application during each log in. This can be with a text message, a
            phone call, or an authenticator app. Downloading an authenticator app to your
            phone, such as one of the following, is one of the more secure and easy to use options:</p>
        <ul>
            <li>- Okta Verify on <a href="https://apps.apple.com/us/app/okta-verify/id490179405">iPhone</a> and
                <a
                    href="https://play.google.com/store/apps/details?id=com.okta.android.auth&hl=en_US&gl=US&pli=1">Android</a>
            </li>
            <li>- Google Authenticator on <a
                    href="https://apps.apple.com/au/app/google-authenticator/id388497605">iPhone</a> and <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">Android</a>
            </li>
            <li>- Microsoft Authenticator on <a
                    href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">iPhone</a> and <a
                    href="https://play.google.com/store/apps/details?id=com.azure.authenticator&referrer=%20adjust_reftag%3Dc6f1p4ErudH2C%26utm_source%3DLanding%2BPage%2BOrganic%2B-%2Bapp%2Bstore%2Bbadges%26utm_campaign%3Dappstore_android">Android</a>
            </li>
        </ul>
        <p class="faq-text">If you no longer have access to the device you used for MFA, you will need to contact support as provided below.</p>
        <h2 class="faq-header">Why is MFA important?</h2>
        <p class="faq-text">MFA is critical for enhancing the security of your online accounts. By requiring multiple forms of verification, MFA significantly reduces the risk of unauthorized access, even if one credential is compromised. According to the Cybersecurity and Infrastructure Security Agency (CISA), using MFA makes you 99% less likely to be hacked.</p>
    </div>
</template>

<script>
export default {
    name: 'helpGeneralMfa',
    props: ['envLinks', 'appsRequiring'],
}
</script>

<style scoped>
@import './help.css';
</style>
