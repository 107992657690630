<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path
                                    d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z">
                                </path>
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Multifactor Authentication (MFA) @ myVermont</h1>
                    <div class="sub-headings">
                        <div class="image-row">
                            <p class="heading-instruction">MFA means requiring an SMS (text), a call or an app to keep your
                                account more secure. It is being used by more myVermont applications each day. Read here
                                to understand how to enroll in one or more MFA options and use them to access
                                applications.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <h2 class="faq-header">Why is MFA important?</h2>
                <p class="faq-text">MFA is critical for enhancing the security of your online accounts. By requiring multiple forms of verification, MFA significantly reduces the risk of unauthorized access, even if one credential is compromised. According to the Cybersecurity and Infrastructure Security Agency (CISA), using MFA makes you 99% less likely to be hacked.</p>
                <div class="help-two-pane">
                    <div class="help-pane1">
                        <h2 class="faq-header">When is MFA enrollment required?</h2>

                        <p class="faq-text">Whether you have a new account or have had a myVermont account for several
                            years, you will only be required to enroll when an application you are using requires it.
                            Only a few myVermont applications require it as of April 2025, but it is expected to become
                            the standard for all myVermont applications in the near future. You will know when it is
                            required because the first time you log into an application after it has become a
                            requirement, you will be asked to set up one of the MFA options discussed below.</p>
                        <h2 class="faq-header">What does MFA enrollment require me to do?</h2>
                        <p class="faq-text">You will have to choose at least one of several security options in addition
                            to your password in order to access the application during each log in. This can be agreeing
                            to text message verification, a phone call verification, or verification through an
                            authenticator app. Downloading an authenticator app to your phone, such as one of the
                            following, is one of the more secure and easy to use options.</p>
                        <ul>
                            <li>- Okta Verify on <a
                                    href="https://apps.apple.com/us/app/okta-verify/id490179405">iPhone</a> and
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.okta.android.auth&hl=en_US&gl=US&pli=1">Android</a>
                            </li>
                            <li>- Google Authenticator on <a
                                    href="https://apps.apple.com/au/app/google-authenticator/id388497605">iPhone</a> and
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">Android</a>
                            </li>
                            <li>- Microsoft Authenticator on <a
                                    href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">iPhone</a>
                                and <a
                                    href="https://play.google.com/store/apps/details?id=com.azure.authenticator&referrer=%20adjust_reftag%3Dc6f1p4ErudH2C%26utm_source%3DLanding%2BPage%2BOrganic%2B-%2Bapp%2Bstore%2Bbadges%26utm_campaign%3Dappstore_android">Android</a>
                            </li>
                        </ul>
                        <p class="faq-text">If you no longer have access to the device you used for MFA, you will need
                            to contact support as provided below.</p>
                    </div>
                    <div class="help-pane1">
                        <img src="../../assets/mfaOptions.png" />
                    </div>
                </div>
                <div class="help-two-pane">
                    <div class="help-pane1">
                        <h2 class="faq-header">How do I use phone MFA?</h2>
                        <p class="faq-text"> Should you choose Phone authentication, complete the questions presented. Choose if you would like to receive the code via SMS (text) or
                            voice call. The default country is United States. Then enter your phone number (or the phone
                            number where you will receive the code). Please note, this phone number can be changed
                            later, but it will remain as the number on file for your authentication every time you log in. 

                            Once you click to receive a code, whether via SMS or a call, enter the code that was sent through your preferred method of authentication in the field present. Then click on Verify.
                        </p>
                    </div>
                    <div class="help-pane2">
                        <img src="../../assets/mfaPhone1.png" />
                    </div>
                    <div class="help-pane3">
                        <img src="../../assets/mfaPhone2.png" />
                    </div>
                </div>
                <div class="help-two-pane">
                    <div class="help-pane1">
                        <h2 class="faq-header">How do I use an app for MFA?</h2>
                        <p class="faq-text">To set up Multi-Factor Authentication (MFA) using an app like Okta Verify or another authenticator, follow these steps:</p>
                        <ol class="faq-text">
                            <li><strong>1. Download the App:</strong> Choose and install one of the authenticator apps provided in the links above on your smartphone.</li>
                            <li><strong>2. Open the App:</strong> Launch the app on your phone.</li>
                            <li><strong>3. Scan the QR Code:</strong> On your computer, you'll see a QR code. Use your phone's authenticator app to scan this code.</li>
                            <li><strong>4. Enter the Code:</strong> The app will generate a code. Enter this code on your computer to complete the setup.</li>
                        </ol>
                        <p class="faq-text">
                            The exact steps may vary slightly depending on the app, but each will guide you through the process.
                        </p>
                        <h2 class="faq-header">Can I have more than one form MFA?</h2>
                        <p class="faq-text"> Yes! Having more than one form of MFA is a great idea. This way, if you lose a device, or you have bad cell service, you might have another way in when you need it. When you first enroll in MFA, before continuing to the myVermont application you are trying to use, you will be given additional options. You can also add and replace options at a later date by going to <a href="https://my.vermont.gov/enduser/settings">https://my.vermont.gov/enduser/settings</a>.
                        </p>
                    </div>
                    <div class="help-pane2">
                        <img src="../../assets/mfaMore.png" />
                    </div>

                </div>
                <div class="noteAlt">
                    <i><font-awesome-icon icon="info-circle" /></i>
                    <div class="noteText">
                        <p>If you do not find an answer here, please review the myVermont help page for the department
                            and app you are trying to use, and use the contact information on the following pages for
                            additional assistance:</p>
                        <ul>
                            <li v-for="help in allHelp" v-bind:key="help.code">
                                <router-link :to="'/' + help.code"><a>{{ help.desc }}</a></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import allHelp from '../allHelp.json'


export default {
    name: 'help-benefits',
    props: ['envLinks'],
    components: {},
    data() {
        return {
            allHelp: allHelp,
            error: ""
        }
    },
    created() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        this.error = urlParams.get('error')
        console.log(this.error)
    }
}
</script>

<style scoped>
@import './help.css';
</style>
